@import url("https://fonts.googleapis.com/css2?family=Lato&family=Oswald:wght@300;400&family=Roboto:wght@300;400;500&display=swap");

* {
    outline: none !important;
}

body {
    margin: 0;
    color: #212529;
    text-align: left;
    background-color: #f2f2f2;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@media screen and (min-width: 600px) {
    #root {
        /* background: linear-gradient(130.49deg, #1f314c 0%, #051731 93.26%); */
        min-height: 720px !important;
        position: relative;
        /* z-index: -1; */
        overflow-x: hidden;
        background-image: url("/bg_banner.svg");
        background-size: auto;
        background-position: top center;
        background-repeat: no-repeat;
    }

    #root::after {
        content: "";
        width: 160%;
        min-height: 720px !important;
        position: absolute;
        left: -30%;
        top: 0;
        z-index: -1;
        border-radius: 0 0 50% 50%;
        background: linear-gradient(130.49deg, #1f314c 0%, #051731 93.26%);
    }
}

@media screen and (max-width: 600px) {
    #root {
        /* background: linear-gradient(130.49deg, #1f314c 0%, #051731 93.26%); */
        min-height: 720px !important;
        position: relative;
        /* z-index: -1; */
        overflow-x: hidden;
        background-image: url("/bg_banner.svg");
        background-size: auto;
        background-position: top center;
        background-repeat: no-repeat;
    }

    #root::after {
        content: "";
        width: 160%;
        min-height: 400px !important;
        position: absolute;
        left: -30%;
        top: 0;
        z-index: -1;
        border-radius: 0 0 50% 50%;
        background: linear-gradient(130.49deg, #1f314c 0%, #051731 93.26%);
    }
}

.MuiButton-label {
    font-size: inherit !important;
}

a {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}
